/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    strong: "strong",
    em: "em",
    blockquote: "blockquote",
    hr: "hr",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "What's up?"), "\n", React.createElement(_components.h1, null, "H1"), "\n", React.createElement(_components.h2, null, "H2"), "\n", React.createElement(_components.h3, null, "H3"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "bold text"), "\n", React.createElement(_components.em, null, "ital text")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "blockquote"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.google.com"
  }, "A test link")), "\n", React.createElement(_components.p, null, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
